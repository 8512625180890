/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';

export interface INotAuthorizedProps {
  isAuthenticated: boolean;
  onLoginClick: () => void;
}

export const NotAuthorized = ({
  isAuthenticated,
  onLoginClick,
}: INotAuthorizedProps) => {
  const title = isAuthenticated
    ? '401 - Not Authorized'
    : '401 - Not Logged In';

  return (
    <Text>
      <div css={styles.base}>
        <div css={styles.header}>
          <Icons.fingerprint fill={COLORS.WHITE} size={42} />
          <h1 css={styles.h1}>{title}</h1>
        </div>
        <Button style={styles.linkButton} onClick={onLoginClick}>
          <>{isAuthenticated ? 'Switch account' : 'Please login'}</>
        </Button>
      </div>
    </Text>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  header: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
  }),
  linkButton: css({
    textAlign: 'center',
    fontSize: 16,
    cursor: 'pointer',
    border: `solid 1px ${color.format(0.2)}`,
    color: 'white',
    background: color.format(0.03),
    borderRadius: 4,
    padding: '15px 30px',
  }),
  h1: css({
    margin: 5,
    color: 'white',
  }),
};
