/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { useMouseOver } from '../../../../hooks/useMouseOver.ts';
import { Button } from '../../../Button/Button.tsx';
import { Icons } from '../../../Icon/Icons.tsx';
import { SelectionListClickEventHandler } from '../../../SelectionList/types.ts';
import { Text } from '../../../Text/Text.tsx';
import * as themes from '../../themes.ts';
import { IToolButton, ToolButtonClickEventHandler } from '../../types.ts';
import { DropdownPopup } from '../DropdownPopup.tsx';

export type ToolButtonProps = {
  tool: IToolButton;
  theme?: 'LIGHT' | 'DARK';
  isMenuVisible?: boolean;
  borderRadius?: number | string;
  onMouseDown?: ToolButtonClickEventHandler<IToolButton>;
  onDropdownHide?: () => void;
  onDropdownClick?: SelectionListClickEventHandler;
  hasHoverBorder?: boolean;
};

/**
 * A single ToolButtonSet button.
 */
export const ToolButton: React.FC<ToolButtonProps> = ({
  isMenuVisible = false,
  borderRadius,
  onDropdownHide,
  onDropdownClick,
  theme = 'DARK',
  hasHoverBorder = true,
  tool,
}) => {
  const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();
  const { label, isSelected = false, dropdown, isEnabled } = tool;
  const Icon = tool.icon;

  const cursor = isEnabled && !isSelected ? 'pointer' : 'default';
  const hasLabel = Boolean(label);
  const hasMenu = Boolean(dropdown);

  const iconTheme = themes.icon(theme);
  const labelTheme = themes.label(theme);
  const toolBackgroundTheme = themes.toolBackground(theme);

  const textColor = isEnabled
    ? isMouseOver && !isSelected
      ? labelTheme.OVER
      : isSelected
        ? labelTheme.SELECTED
        : labelTheme.DEFAULT
    : labelTheme.DISABLED;

  const iconColor = isEnabled
    ? isMouseOver && !isSelected
      ? iconTheme.OVER
      : isSelected
        ? iconTheme.SELECTED
        : iconTheme.DEFAULT
    : iconTheme.DISABLED;

  const styles = {
    content: css({
      cursor,
      borderRadius,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
      height: 32,
      padding: '4px 8px',
      border: hasHoverBorder ? `1px solid ${color.format(0)}` : undefined,
      background: isSelected ? toolBackgroundTheme.SELECTED : undefined,
      ':hover': {
        background: !isSelected ? toolBackgroundTheme.OVER : undefined,
        border: hasHoverBorder ? `1px solid ${color.format(-0.04)}` : undefined,
      },
    }),
    label: css({
      marginLeft: Icon ? 8 : 15,
      marginRight: hasMenu ? 0 : Icon ? 8 : 15,
      color: textColor,
    }),
    dropdownIcon: css({
      position: 'relative',
      marginRight: Icon ? 0 : 5,
      marginLeft: -3,
      marginBottom: -1,
    }),
  };

  return (
    <Button
      dataTest={tool.dataTest}
      isEnabled={tool.isEnabled}
      onClick={() => tool.onClick?.({ id: tool.id, tool })}
      tooltip={tool.tooltip}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <>
        <div css={styles.content}>
          {Icon && <Icon size={24} fill={iconColor} />}
          {label && (
            <Text cursor={cursor} selectable={false} style={styles.label}>
              {label}
            </Text>
          )}
          {hasMenu && hasLabel && (
            <div css={styles.dropdownIcon}>
              <Icons.arrowDropDown fill={iconColor} />
            </div>
          )}
        </div>
        {dropdown && isMenuVisible && (
          <DropdownPopup
            {...dropdown}
            onHide={onDropdownHide}
            onClick={onDropdownClick}
          />
        )}
      </>
    </Button>
  );
};
